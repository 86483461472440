import React, { useState, useEffect, useCallback } from "react";
import { Alert, LinearProgress, Snackbar } from "@mui/material";

const colors = {
  success: "#2e7d32",
  error: "#d32f2f",
  warning: "#f9a825",
  info: "#1976d2",
};

export function useSnackbarToast() {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [options, setOptions] = useState<{
    severity: "error" | "info" | "success" | "warning";
  }>({ severity: "success" });

  const snackToast = useCallback(
    (
      message: string,
      options: { severity: "error" | "info" | "success" | "warning" },
    ) => {
      setMessage(message);
      setOptions(options);
      setOpen(true);
    },
    [],
  );

  function SnackbarToastContainer() {
    const [progress, setProgress] = useState(100);

    useEffect(() => {
      if (open) {
        const timer = setTimeout(() => setOpen(false), 3000);
        const interval = setInterval(() => {
          setProgress((prevProgress) => {
            if (prevProgress <= 0) {
              clearInterval(interval);
              return 0;
            }
            return prevProgress - 1;
          });
        }, 30);
        return () => {
          clearTimeout(timer);
          clearInterval(interval);
        };
      }
    }, [open]);

    return (
      <Snackbar
        open={open}
        onClose={(event, reason) => {
          if (reason === "clickaway") return;
          setOpen(false);
        }}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <div>
          <Alert
            variant="filled"
            severity={options.severity}
            sx={{
              borderRadius: "0px",
              borderTopLeftRadius: "4px",
              borderTopRightRadius: "4px",
            }}
          >
            {message}
          </Alert>
          <LinearProgress
            variant="determinate"
            value={progress}
            sx={{
              backgroundColor: colors[options.severity],
              borderBottomLeftRadius: "4px",
              borderBottomRightRadius: "4px",
              "& .MuiLinearProgress-bar": {
                backgroundColor: "white",
              },
            }}
          />
        </div>
      </Snackbar>
    );
  }

  return { SnackbarToastContainer, snackToast };
}
