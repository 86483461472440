import React, { Suspense } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import { checkUserLoggedInFunction } from "./components/auth/login";

import GlobalStoreProvider from "./hooks/globalStore";
import LoadingScreen from "./components/core/loadingScreen";
import { HelmetProvider } from "react-helmet-async";

const Root = React.lazy(() => import("./pages/root"));
const Dashboard = React.lazy(() => import("./pages/dashboard"));
const ErrorPage = React.lazy(() => import("./pages/404"));
const UPCPage = React.lazy(() => import("./pages/upc"));

function Protected(props: { children: React.ReactNode }) {
  const { children } = props;
  return (
    <Suspense fallback={<LoadingScreen />}>
      {checkUserLoggedInFunction() ? children : <Navigate to="/" />}
    </Suspense>
  );
}

function App() {
  return (
    <GlobalStoreProvider>
      <HelmetProvider>
        <Router>
          <Routes>
            <Route
              path="/"
              element={
                <Suspense fallback={<LoadingScreen />}>
                  <Root />
                </Suspense>
              }
            />
            <Route
              path="/dashboard"
              element={
                <Protected>
                  <Dashboard />
                </Protected>
              }
            />
            <Route
              path="/upc"
              element={
                <Protected>
                  <UPCPage />
                </Protected>
              }
            />
            <Route
              path="*"
              element={
                <Suspense fallback={<LoadingScreen />}>
                  <ErrorPage />
                </Suspense>
              }
            />
          </Routes>
        </Router>
      </HelmetProvider>
    </GlobalStoreProvider>
  );
}

export default App;
