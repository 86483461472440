import React, { useState, useEffect, useLayoutEffect } from "react";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import bevzlogo from "../../assets/bevz-logo.webp";
import { MdLock, MdLockOpen } from "react-icons/md";
import { FaUserCircle, FaKey } from "react-icons/fa";
import { toast, ToastContentProps } from "react-toastify";
import {
  Checkbox,
  FormControlLabel,
  TextField,
  InputAdornment,
  Divider,
  Link,
  Alert,
} from "@mui/material";

import { LoadingButton } from "@mui/lab";

import {
  apiUrl,
  localTokenName,
  localWSTokenName,
  localAccessTokenName,
} from "../../utils/envVars";
import jwtDecodedType from "../../types/jwtDecoded";

import { useSnackbarToast } from "../../hooks/snackbarToast";

/**
 * Checks if the user is logged in by verifying the presence and validity of a JWT token stored in sessionStorage.
 * @returns A boolean value indicating whether the user is logged in or not.
 */
export const checkUserLoggedInFunction = (): boolean => {
  const refreshToken = localStorage.getItem(localTokenName) as string;
  const accessToken = sessionStorage.getItem(localAccessTokenName) as string;

  if (refreshToken || accessToken) {
    const decodedToken: jwtDecodedType = jwtDecode(refreshToken ?? accessToken);
    if (!decodedToken) {
      localStorage.removeItem(localTokenName);
      sessionStorage.removeItem(localAccessTokenName);
      sessionStorage.removeItem(localWSTokenName);

      return false;
    }

    const currentTime = Date.now() / 1000;

    if (decodedToken.exp < currentTime) {
      localStorage.removeItem(localTokenName);
      sessionStorage.removeItem(localAccessTokenName);
      sessionStorage.removeItem(localWSTokenName);

      return false;
    } else {
      return true;
    }
  } else {
    return false;
  }
};

/**
 * Checks if the user is logged in by verifying the presence and validity of a JWT token stored in sessionStorage.
 * @returns A Promise that resolves to a boolean value indicating whether the user is logged in or not.
 */
export const checkUserLoggedIn = (): Promise<boolean> => {
  return new Promise((resolve, reject) => {
    const refreshToken = localStorage.getItem(localTokenName) as string;
    const accessToken = sessionStorage.getItem(localAccessTokenName) as string;
    // const wsToken = sessionStorage.getItem(localWSTokenName);

    if (refreshToken || accessToken) {
      const decodedToken: jwtDecodedType = jwtDecode(
        refreshToken ?? accessToken,
      );
      if (!decodedToken) {
        localStorage.removeItem(localTokenName);
        sessionStorage.removeItem(localAccessTokenName);
        sessionStorage.removeItem(localWSTokenName);

        resolve(false);
        return;
      }

      const currentTime = Date.now() / 1000;

      if (decodedToken.exp < currentTime) {
        localStorage.removeItem(localTokenName);
        sessionStorage.removeItem(localAccessTokenName);
        sessionStorage.removeItem(localWSTokenName);

        resolve(false);
      } else {
        resolve(true);
      }
    } else {
      resolve(false);
    }
  });
};

export default function LoginComponent() {
  const navigate = useNavigate();
  const { SnackbarToastContainer, snackToast } = useSnackbarToast();
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    username: "",
    password: "",
    rememberMe: false,
  });

  const [formErrors, setFormErrors] = React.useState({
    username: false,
    password: false,
  });

  const checkLoggedIn = () => {
    checkUserLoggedIn().then((loggedIn) => {
      if (loggedIn) navigate("/dashboard");
    });
  };

  const handleLogin = async () => {
    setLoading(true);

    let formErrors = {
      username: formData.username.length === 0,
      password: formData.password.length === 0,
    };

    setFormErrors(formErrors);
    if (formErrors.username || formErrors.password) {
      setLoading(false);
      return;
    }

    axios
      .post(`${apiUrl}/login`, formData)
      .then((response) => {
        if (response.data.status === "success") {
          if (formData.rememberMe) {
            localStorage.setItem(localTokenName, response.data.refreshToken);
          }
          sessionStorage.setItem(localWSTokenName, response.data.wsToken);
          sessionStorage.setItem(
            localAccessTokenName,
            response.data.accessToken,
          );

          navigate("/dashboard");
        } else {
          snackToast("Invalid username or password", { severity: "error" });
        }
      })
      .catch((error) => {
        snackToast("An backend error occurred. Please try again later.", {
          severity: "error",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useLayoutEffect(() => {
    checkLoggedIn();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (formData.username.length > 0) {
      setFormErrors({ ...formErrors, username: false });
    } else if (formData.password.length > 0) {
      setFormErrors({ ...formErrors, password: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);

  return (
    <div className="flex flex-col justify-center items-center py-4 gap-4 w-full sm:w-[28rem] m-auto bg-white sm:rounded-2xl sm:shadow-xl">
      <SnackbarToastContainer />
      <div className="flex flex-col">
        <img src={bevzlogo} alt="Bevz" className="h-24 rounded-full m-auto" />
        <div className="text-2xl font-bold mt-2">Bevz UPC Box</div>
        <div className="m-auto">Sign in to continue.</div>
      </div>
      <form className="w-5/6">
        <TextField
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <FaUserCircle size={22} />
              </InputAdornment>
            ),
          }}
          error={formErrors.username}
          margin="normal"
          required
          fullWidth
          name="username"
          label="Username"
          id="username"
          onChange={(e) =>
            setFormData({ ...formData, username: e.target.value })
          }
        />
        <TextField
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <FaKey size={20} />
              </InputAdornment>
            ),
          }}
          error={formErrors.password}
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          autoComplete="current-password"
          onChange={(e) =>
            setFormData({ ...formData, password: e.target.value })
          }
        />
        <div className="w-full flex justify-between pt-3 pb-8">
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={formData.rememberMe}
                onChange={(e) => {
                  setFormData({ ...formData, rememberMe: e.target.checked });
                }}
              />
            }
            label="Remember me"
          />
          <LoadingButton
            // type="submit"
            loading={loading}
            loadingPosition="end"
            endIcon={<MdLockOpen />}
            variant="contained"
            onClick={handleLogin}
          >
            Login
          </LoadingButton>
        </div>
        <Alert severity="info">Check Remember me to stay logged in.</Alert>
      </form>
      <div className="w-5/6">
        <Divider />
        <div className="my-4 flex justify-between">
          <Link href="#" underline="hover">
            Docs
          </Link>
          <Link href="#" underline="hover">
            Help
          </Link>
        </div>
      </div>
    </div>
  );
}
