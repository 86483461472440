import React, { useContext } from "react";
import GlobalStoreType from "../types/globalStore";

interface Props {
  children?: React.ReactNode;
}

export const GlobalStoreContext = React.createContext<GlobalStoreType>({
  user: "Unauntheticated User",
});
export const useGlobalStoreContext = () => useContext(GlobalStoreContext);

export default function GlobalStoreProvider({ children }: Props) {
  const [user, setUser] = React.useState<string>("Unauntheticated User");

  return (
    <GlobalStoreContext.Provider
      value={{
        user,
      }}
    >
      {children}
    </GlobalStoreContext.Provider>
  );
}
